import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import Address from '../components/Contact/Address'
import Chat from '../components/Contact/Chat'
import ContactBanner from '../components/Contact/ContactBanner'
import ContactNumbers from '../components/Contact/ContactNumbers'
import TollFree from '../components/Contact/TollFree'
import Seo from '../components/Layout/Seo'
import Site from '../components/Layout/Site'
import {
  Field,
  FieldContainer,
  FieldError,
  FieldGroup,
  Label,
  FieldCheckbox,
} from '../components/UI/Forms'
import { Row, Col, Container } from '../components/UI/Grid'
import { Section } from '../components/UI/Section'
import Button from '../components/UI/Button'
import Element from '../components/UI/Element'
import { schema } from '../utils/yupSchema'
import Select from 'react-select'

import { useProductsData } from '../hooks/use-products-data'
import { Title } from '../components/Shared/SectionHeader.styles'
import { Anchor } from '../components/UI/Typography'
import CheckIcon from '../components/Icons/CheckIcon'
import { navigate } from 'gatsby'
import DefaultVector from '../components/Shared/DefaultVector'
import axios from 'axios'
import { fileChecksum } from '../utils/checksum'

const createPresignedUrl = async (file, byte_size, checksum) => {
  let options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      file: {
        filename: file.name,
        byte_size: byte_size,
        checksum: checksum,
        content_type: 'application/pdf',
        metadata: {
          message: 'resume for parsing',
        },
      },
    }),
  }
  let res = await fetch(`${process.env.GATSBY_API_URL}/presigned_url`, options)
  if (res.status !== 200) return res
  return await res.json()
}

const ContactUs = ({ location }) => {
  const {
    register,
    unregister,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const [inquiryType, setInquiryType] = useState('Customers')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [defaultProduct, setDefaultProduct] = useState({})
  const [products, setProducts] = useState([])
  const [error, setError] = useState(null)
  const [processing, setProcessing] = useState(true)

  useEffect(() => {
    axios
      .get(`${process.env.GATSBY_API_URL}/products`)
      .then(res => {
        setProducts(res.data)
      })
      .finally(setProcessing(false))
  }, [])

  const withOthers = [...products, { name: 'Others', id: 0 }]

  useEffect(() => {
    if (location?.state?.productId) {
      setDefaultProduct(products.find(i => i.id === parseInt(location.state.productId)))
      reset({
        interests: [{ id: defaultProduct?.id, name: defaultProduct?.name }],
      })
    }
    if (location?.state?.inquiryType) {
      setInquiryType(location.state.inquiryType)
    }
  }, [location?.state?.productId, location?.state?.inquiryType, products, reset, defaultProduct])

  useEffect(() => {
    switch (inquiryType) {
      case 'Customers':
        register('company')
        register('location')
        register('products')
        unregister('pdf')
        reset({
          inquiry_type: 'Customers',
        })
        break
      case 'Careers':
        unregister('company')
        unregister('location')
        unregister('products')
        reset({
          inquiry_type: 'Careers',
        })
        break
      case 'Suppliers':
        unregister('products')
        unregister('pdf')
        register('company')
        register('location')
        reset({
          inquiry_type: 'Suppliers',
        })
        break
      case 'General Inquiry':
        unregister('products')
        unregister('company')
        unregister('location')
        unregister('pdf')
        reset({
          inquiry_type: 'General Inquiry',
        })
        break
      default:
        return null
    }
  }, [inquiryType, register, reset, unregister])

  const handleSelectChange = e => {
    setInquiryType(e.target.value)
  }

  const onSubmit = async data => {
    setIsSubmitting(true)
    let resume = null

    if (inquiryType === 'Careers') {
      const checksum = await fileChecksum(data.pdf[0])
      const presignedFileParams = await createPresignedUrl(data.pdf[0], data.pdf[0].size, checksum)

      // 2) send file to said PUT request (to S3)
      const s3PutOptions = {
        method: 'PUT',
        headers: presignedFileParams.direct_upload.headers,
        body: data.pdf[0],
      }
      let awsRes = await fetch(presignedFileParams.direct_upload.url, s3PutOptions)
      if (awsRes.status !== 200) return awsRes

      resume = presignedFileParams.blob_signed_id
    }

    try {
      const response = await fetch(`${process.env.GATSBY_API_URL}/messages`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          inquiry_type: data.inquiry_type,
          full_name: data.full_name,
          email: data.email,
          company: data.company,
          location: data.location,
          interests: data.interests?.map(data => data.name),
          contact_number: data.contact_number,
          message: data.message,
          pdf: resume,
        }),
      })
      const json = await response.json()
      if (json.status === 'SUCCESS') {
        navigate('/thank-you', {
          state: { fromContactUs: true, name: data.full_name },
        })
      } else {
        // show error message
        setError(json.message)
      }
    } catch (err) {
      // show error
      console.log(data)
      setError(err.message)
    }
    setIsSubmitting(false)
  }

  if (processing) {
    return null
  }

  return (
    <Site>
      <Seo title="Contact Us" />
      <ContactBanner />
      <Section py={{ _: 16, lg: 0 }}>
        <DefaultVector>
          <Container>
            <Row>
              <Col col={{ lg: 6 }} mb={{ _: 8, lg: 0 }}>
                <Title size="display-4">Get In Touch</Title>
                <Element as="p" mb={10}>
                  Kindly provide your details and we will get back to you as soon as possible.
                </Element>
                <Element as="form" onSubmit={handleSubmit(onSubmit)} rowGap="6" display="grid">
                  {error && error}
                  <FieldGroup>
                    <Label required>Inquiry Type</Label>
                    <FieldContainer>
                      <Field
                        {...register('inquiry_type')}
                        as="select"
                        onChange={e => handleSelectChange(e)}
                        value={inquiryType}
                      >
                        <option value="Customers">Customers</option>
                        <option value="Careers">Careers</option>
                        <option value="Suppliers">Suppliers</option>
                        <option value="General Inquiry">General Inquiry</option>
                      </Field>
                    </FieldContainer>
                  </FieldGroup>

                  <FieldGroup errors={errors.full_name}>
                    <Label htmlFor="name" required>
                      Name
                    </Label>
                    <FieldContainer>
                      <Field {...register('full_name')} placeholder="e.g. Juan Dela Cruz" />
                    </FieldContainer>
                    {errors.full_name && <FieldError>{errors.full_name.message}</FieldError>}
                  </FieldGroup>

                  {inquiryType !== 'Careers' && (
                    <>
                      <FieldGroup errors={errors.company}>
                        <Label htmlFor="company" required>
                          Company
                        </Label>
                        <FieldContainer>
                          <Field {...register('company')} />
                        </FieldContainer>
                        {errors.company && <FieldError>{errors.company.message}</FieldError>}
                      </FieldGroup>

                      <FieldGroup errors={errors.location}>
                        <Label htmlFor="location" required>
                          Location
                        </Label>
                        <FieldContainer>
                          <Field {...register('location')} />
                        </FieldContainer>
                        {errors.location && <FieldError>{errors.location.message}</FieldError>}
                      </FieldGroup>
                    </>
                  )}

                  <FieldGroup errors={errors.contact_number}>
                    <Label htmlFor="contact_number" required>
                      Contact Number
                    </Label>
                    <FieldContainer>
                      <Field {...register('contact_number')} />
                    </FieldContainer>
                    {errors.contact_number && (
                      <FieldError>{errors.contact_number.message}</FieldError>
                    )}
                  </FieldGroup>

                  <FieldGroup errors={errors.email}>
                    <Label htmlFor="email" required>
                      Email Address
                    </Label>
                    <FieldContainer>
                      <Field {...register('email')} placeholder="e.g. jdl@website.com" />
                    </FieldContainer>
                    {errors.email && <FieldError>{errors.email.message}</FieldError>}
                  </FieldGroup>

                  {inquiryType === 'Customers' ? (
                    <Controller
                      name="interests"
                      control={control}
                      render={({ field }) => (
                        <FieldGroup errors={errors.interests}>
                          <Label htmlFor="product" required>
                            Product of Interest
                          </Label>
                          <FieldContainer>
                            <Select
                              options={withOthers}
                              value={defaultProduct}
                              isMulti
                              classNamePrefix="react-select"
                              getOptionLabel={option => option.name}
                              getOptionValue={option => option.id}
                              {...field}
                            />
                          </FieldContainer>
                          {errors.interests && <FieldError>{errors.interests.message}</FieldError>}
                        </FieldGroup>
                      )}
                    />
                  ) : null}

                  <FieldGroup errors={errors.message}>
                    <Label htmlFor="message" required>
                      Message
                    </Label>
                    <FieldContainer>
                      <Field
                        as="textarea"
                        {...register('message')}
                        placeholder="To be able to serve you better, please include information that may be relevant to your inquiry here."
                      />
                    </FieldContainer>
                    {errors.message && <FieldError>{errors.message.message}</FieldError>}
                  </FieldGroup>

                  {inquiryType === 'Careers' && (
                    <FieldGroup errors={errors.pdf}>
                      <Label htmlFor="pdf" required>
                        Upload Resume
                      </Label>
                      <FieldContainer>
                        <Field
                          type="file"
                          {...register('pdf')}
                          accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        />
                      </FieldContainer>
                      {errors.pdf && <FieldError>{errors.pdf.message}</FieldError>}
                    </FieldGroup>
                  )}

                  {/* <FieldGroup>
                    <FieldCheckbox inline>
                      <Row gutterSize={2}>
                        <Col col="auto">
                          <label>
                            <input type="checkbox" {...register('policy')} />
                            <div className="square">
                              <CheckIcon />
                            </div>
                          </label>
                        </Col>
                        <Col col="fill">
                          <Element size="caption">
                            I hereby authorize Fastech to process my personal and sensitive
                            information in accordance with the{' '}
                            <Anchor
                              href="/privacy-policy"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <u>Privacy Policy</u>
                            </Anchor>{' '}
                            given.
                          </Element>
                        </Col>
                      </Row>
                    </FieldCheckbox>
                    {errors.policy && <FieldError>{errors.policy.message}</FieldError>}
                  </FieldGroup> */}

                  <Button type="submit" variant="brand" disabled={isSubmitting}>
                    {isSubmitting ? 'Sending...' : 'Submit'}
                  </Button>
                </Element>
              </Col>
              <Col col={{ lg: 6 }}>
                <Element pl={{ _: 0, lg: 8 }}>
                  <Address />
                  <ContactNumbers />
                  <TollFree />
                  <Chat />
                </Element>
              </Col>
            </Row>
          </Container>
        </DefaultVector>
      </Section>
    </Site>
  )
}

export default ContactUs
