import React from "react";
import Icon from "../UI/Icon";

const ContactIcon = () => {
  return (
    <Icon viewBox="0 0 31 30">
      <g clipPath="url(#clip0_6401_2607)">
        <path d="M28 3.75H3C1.625 3.75 0.5 4.875 0.5 6.25V23.75C0.5 25.125 1.625 26.25 3 26.25H28C29.375 26.25 30.4875 25.125 30.4875 23.75L30.5 6.25C30.5 4.875 29.375 3.75 28 3.75ZM10.5 7.5C12.575 7.5 14.25 9.175 14.25 11.25C14.25 13.325 12.575 15 10.5 15C8.425 15 6.75 13.325 6.75 11.25C6.75 9.175 8.425 7.5 10.5 7.5ZM18 22.5H3V21.25C3 18.75 8 17.375 10.5 17.375C13 17.375 18 18.75 18 21.25V22.5ZM22.8125 17.5H24.55C24.75 17.5 24.925 17.5875 25.05 17.75L26.425 19.5625C26.6125 19.8125 26.5875 20.1625 26.3625 20.375L24.6625 22.075C24.4375 22.3 24.0625 22.325 23.825 22.125C22.4125 20.925 21.3625 19.3125 20.85 17.4875C20.625 16.7 20.5 15.8625 20.5 15C20.5 14.1375 20.625 13.3 20.85 12.5C21.3625 10.6625 22.4125 9.0625 23.825 7.8625C24.075 7.65 24.4375 7.6875 24.6625 7.9125L26.3625 9.6125C26.5875 9.8375 26.6125 10.1875 26.425 10.425L25.05 12.2375C24.9375 12.4 24.75 12.4875 24.55 12.4875H22.8125C22.5375 13.275 22.375 14.1125 22.375 14.9875C22.375 15.8625 22.5375 16.7125 22.8125 17.5Z" />
      </g>
      <defs>
        <clipPath id="clip0_6401_2607">
          <rect
            width="30"
            height="30"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default ContactIcon;
