import React from "react";
import Icon from "../UI/Icon";

const TollFreeIcon = () => {
  return (
    <Icon viewBox="0 0 31 30">
      <path d="M22.375 5.625C19.9375 5.625 17.3125 6.125 15.5 7.5C13.6875 6.125 11.0625 5.625 8.625 5.625C6.8125 5.625 4.8875 5.9 3.275 6.6125C2.3625 7.025 1.75 7.9125 1.75 8.925V23.025C1.75 24.65 3.275 25.85 4.85 25.45C6.075 25.1375 7.375 25 8.625 25C10.575 25 12.65 25.325 14.325 26.15C15.075 26.525 15.925 26.525 16.6625 26.15C18.3375 25.3125 20.4125 25 22.3625 25C23.6125 25 24.9125 25.1375 26.1375 25.45C27.7125 25.8625 29.2375 24.6625 29.2375 23.025V8.925C29.2375 7.9125 28.625 7.025 27.7125 6.6125C26.1125 5.9 24.1875 5.625 22.375 5.625ZM26.75 21.5375C26.75 22.325 26.025 22.9 25.25 22.7625C24.3125 22.5875 23.3375 22.5125 22.375 22.5125C20.25 22.5125 17.1875 23.325 15.5 24.3875V10C17.1875 8.9375 20.25 8.125 22.375 8.125C23.525 8.125 24.6625 8.2375 25.75 8.475C26.325 8.6 26.75 9.1125 26.75 9.7V21.5375Z" />
    </Icon>
  );
};

export default TollFreeIcon;
