import React from 'react'
import { Row, Col } from '../UI/Grid'
import { Anchor, Heading } from '../UI/Typography'
import ContactIcon from '../Icons/ContactIcon'
import Element from '../UI/Element'

const data = [
  { country: 'Trunk line', tel: '+63-49-543-0476' },
  { country: 'Manila line', tel: '(+632) 8843-0017' },
  { country: 'Fax', tel: '+63-49-543-0352' },
]

const ContactNumbers = () => {
  return (
    <Element mb={10}>
      <Heading size="h4">
        <Row gutterSize={2} mb={2} alignItems="center">
          <Col col="auto">
            <ContactIcon />
          </Col>
          <Col col="fill">Contact Numbers</Col>
        </Row>
      </Heading>

      {data && (
        <Element as="ul" pl={4}>
          {data.map((c, idx) => (
            <li key={idx}>
              <Element as="span" display="inline-block" minWidth={120}>
                {c.country}:
              </Element>{' '}
              <Anchor href={`tel:${c.tel}`}>{c.tel}</Anchor>
            </li>
          ))}
        </Element>
      )}
    </Element>
  )
}

export default ContactNumbers
