import React from "react";
import { Row, Col } from "../UI/Grid";
import { Anchor, Heading } from "../UI/Typography";
import ChatIcon from "../Icons/ChatIcon";
import Element from "../UI/Element";

const Chat = () => {
  return (
    <>
      <Heading size="h4">
        <Row gutterSize={2} mb={2} alignItems="start">
          <Col col="auto">
            <ChatIcon />
          </Col>
          <Col col="fill">
            <Element lineHeight={1.2}>
              Chat With Our
              <br />
              Customer Representatives
            </Element>
          </Col>
        </Row>
      </Heading>
      <Element mt={5}>
        <b>Email Us</b>:{" "}
        <Anchor
          href="mailto:query@fastech.com.ph"
          target="_blank"
          rel="noopener noreferrer"
        >
          query@fastech.com.ph
        </Anchor>
      </Element>
    </>
  );
};

export default Chat;
