import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Banner from "../Shared/Banner";
import ScaledImage from "../UI/ScaledImage";

const ContactBanner = () => {
  return (
    <Banner description="Contact Us" col={8}>
      <ScaledImage bg={true}>
        <StaticImage
          src="../../images/banner-contact.jpg"
          alt="banner"
          layout="fullWidth"
        />
      </ScaledImage>
    </Banner>
  );
};

export default ContactBanner;
