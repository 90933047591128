import React from "react";
import Icon from "../UI/Icon";

const MapMarkerIcon = () => {
  return (
    <Icon viewBox="0 0 31 30">
      <path d="M15.5 2.5C10.6625 2.5 6.75 6.4125 6.75 11.25C6.75 16.4625 12.275 23.65 14.55 26.3875C15.05 26.9875 15.9625 26.9875 16.4625 26.3875C18.725 23.65 24.25 16.4625 24.25 11.25C24.25 6.4125 20.3375 2.5 15.5 2.5ZM15.5 14.375C13.775 14.375 12.375 12.975 12.375 11.25C12.375 9.525 13.775 8.125 15.5 8.125C17.225 8.125 18.625 9.525 18.625 11.25C18.625 12.975 17.225 14.375 15.5 14.375Z" />
    </Icon>
  );
};

export default MapMarkerIcon;
