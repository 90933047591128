import * as yup from 'yup'

export const schema = yup.object().shape({
  inquiry_type: yup.string().required('This is required.'),
  full_name: yup.string().required('This is required.'),
  email: yup
    .string()
    .required('This is required.')
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,13}$/i, 'Please enter a valid email.'),
  company: yup
    .string()
    .when('inquiry_type', {
      is: 'Customers',
      then: schema => schema.required('This is required.'),
      otherwise: schema => schema.min(0),
    })
    .when('inquiry_type', {
      is: 'Suppliers',
      then: schema => schema.required('This is required.'),
      otherwise: schema => schema.min(0),
    })
    .when('inquiry_type', {
      is: 'General Inquiry',
      then: schema => schema.required('This is required.'),
      otherwise: schema => schema.min(0),
    }),
  location: yup
    .string()
    .when('inquiry_type', {
      is: 'Customers',
      then: schema => schema.required('This is required.'),
      otherwise: schema => schema.min(0),
    })
    .when('inquiry_type', {
      is: 'Suppliers',
      then: schema => schema.required('This is required.'),
      otherwise: schema => schema.min(0),
    })
    .when('inquiry_type', {
      is: 'General Inquiry',
      then: schema => schema.required('This is required.'),
      otherwise: schema => schema.min(0),
    }),
  interests: yup.mixed().when('inquiry_type', {
    is: 'Customers',
    then: schema => schema.test('required', 'This is required', value => value.length > 0),
  }),
  contact_number: yup
    .string()
    .required('This is required.')
    .matches(
      /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/,
      'Please enter a valid phone number.',
    ),
  message: yup.string().required('This is required.'),
  pdf: yup.mixed().when('inquiry_type', {
    is: 'Careers',
    then: schema =>
      schema
        .test('required', 'This is required', value => value.length !== 0)
        .test(
          'fileSize',
          'This file size is too large to be uploaded. Files larger than 5MB are not currently supported.',
          value => value[0]?.size <= 5000000,
        ),
  }),
})
