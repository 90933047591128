import React from "react";
import { Row, Col } from "../UI/Grid";
import { Heading, Paragraph } from "../UI/Typography";
import MapMarkerIcon from "../Icons/MapMarkerIcon";

const Address = () => {
  return (
    <>
      <Heading size="h4">
        <Row gutterSize={2} mb={2} alignItems="center">
          <Col col="auto">
            <MapMarkerIcon />
          </Col>
          <Col col="fill">Address</Col>
        </Row>
      </Heading>

      <Paragraph mb={10}>
        Fastech Manufacturing Complex, Ampere Street cor. West Road, Light
        Industry and Science Park I, Cabuyao, Laguna, Philippines 4025
      </Paragraph>
    </>
  );
};

export default Address;
