import React from 'react'
import { Row, Col } from '../UI/Grid'
import { Anchor, Heading } from '../UI/Typography'
import TollFreeIcon from '../Icons/TollFreeIcon'
import Element from '../UI/Element'

const data = [
  { country: 'United States of America', tel: '011-800-1327-8324' },
  { country: 'Canada', tel: '011-800-1327-8324' },
  { country: 'Australia', tel: '0011-800-1327-8324' },
  { country: 'Taiwan', tel: '00-800-1327-8324' },
  { country: 'Hongkong', tel: '00-800-1327-8324' },
  { country: 'United Kingdom', tel: '00-800-1327-8324' },
  { country: 'Germany', tel: '00-800-1327-8324' },
  { country: 'Italy', tel: '00-800-1327-8324' },
  { country: 'France', tel: '00-800-1327-8324' },
  { country: 'Switzerland', tel: '00-800-1327-8324' },
  { country: 'Belgium', tel: '00-800-1327-8324' },
  { country: 'Norway', tel: '00-800-1327-8324' },
  { country: 'Korea', tel: '001-800-1327-8324' },
  { country: 'Singapore', tel: '001-800-1327-8324' },
  { country: 'Japan', tel: '010-800-1327-8324' },
  { country: 'Hongkong', tel: '00-800-1327-8324' },
]

const TollFree = () => {
  return (
    <Element mb={10}>
      <Heading size="h4">
        <Row gutterSize={2} mb={2} alignItems="center">
          <Col col="auto">
            <TollFreeIcon />
          </Col>
          <Col col="fill">Call Us Toll-Free From Your Location</Col>
        </Row>
      </Heading>
      {data && (
        <Element as="ul" pl={4}>
          {data.map((c, idx) => (
            <li key={idx}>
              <Row>
                <Col col="auto">
                  <Element minWidth="203px">{c.country}</Element>
                </Col>
                <Col col="auto">
                  <Anchor href={`tel:${c.tel}`}>{c.tel}</Anchor>
                </Col>
              </Row>
            </li>
          ))}
        </Element>
      )}
      <Element fontWeight="bold" mt={5}>
        If your country is not listed, please call:
      </Element>
      <Element as="ul" pl={4}>
        <li>
          <Anchor href="(+632) 8843-0017">(+632) 8843-0017</Anchor>
        </li>
        <li>
          <Anchor href="tel:+63-49-543-0476">+63-49-543-0476</Anchor>
        </li>
      </Element>
      <Element mt={5}>
        <b>Fax</b>: <Anchor href="tel:+63-49-543-0052">+63-49-543-0052</Anchor>
      </Element>
    </Element>
  )
}

export default TollFree
