import React from "react";
import Icon from "../UI/Icon";

const ChatIcon = () => {
  return (
    <Icon viewBox="0 0 31 30">
      <path d="M25.5 2.5H5.5C4.125 2.5 3.0125 3.625 3.0125 5L3 27.5L8 22.5H25.5C26.875 22.5 28 21.375 28 20V5C28 3.625 26.875 2.5 25.5 2.5ZM9.25 11.25H21.75C22.4375 11.25 23 11.8125 23 12.5C23 13.1875 22.4375 13.75 21.75 13.75H9.25C8.5625 13.75 8 13.1875 8 12.5C8 11.8125 8.5625 11.25 9.25 11.25ZM16.75 17.5H9.25C8.5625 17.5 8 16.9375 8 16.25C8 15.5625 8.5625 15 9.25 15H16.75C17.4375 15 18 15.5625 18 16.25C18 16.9375 17.4375 17.5 16.75 17.5ZM21.75 10H9.25C8.5625 10 8 9.4375 8 8.75C8 8.0625 8.5625 7.5 9.25 7.5H21.75C22.4375 7.5 23 8.0625 23 8.75C23 9.4375 22.4375 10 21.75 10Z" />
    </Icon>
  );
};

export default ChatIcon;
